import api from "@/services/ApiService";

export default {
  getRegistrationMitra(payload) {
    return api().post(`/admin/get-request-registration`, payload);
  },
  getRegistrationMitraDetail(payload) {
    return api().post(`/admin/get-request-registration`, payload);
  },
  approveRegistrationMitra(payload) {
    return api().post(`/admin/get-request-registration/mitra/approve`, payload);
  },
  rejectRegistrationMitra(payload) {
    return api().post(`/admin/get-request-registration/mitra/reject`, payload);
  },
  getRegistrationSupplier(menuName) {
    return api().post(`/admin/get-request-registration`, menuName);
  },
  getRegistrationSupplierDetail(payload) {
    return api().post(`/admin/get-request-registration`, payload);
  },
  getRegistrationAssistant(menuName) {
    return api().post(`/admin/get-request-registration`, menuName);
  },
  getRegistrationAssistantDetail(payload) {
    return api().post(`/admin/get-request-registration`, payload);
  },
  getRegistrationContractor(menuName) {
    return api().post(`/admin/get-request-registration`, menuName);
  },
  getRegistrationContractorDetail(payload) {
    return api().post(`/admin/get-request-registration`, payload);
  },
  getRegistrationSupplierVillage(payload) {
    return api().post("/get_village_by_postal_code", payload);
  },
  approveRegistrationSupplier(payload) {
    return api().post(
      `/admin/get-request-registration/supplier/approve`,
      payload
    );
  },
  rejectRegistrationSupplier(payload) {
    return api().post(
      `/admin/get-request-registration/supplier/reject`,
      payload
    );
  }
};
