<template>
  <div id="MitraRegistrationScreen">
    <v-container>
      <v-card>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Daftar Pengguna</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pa-0">
          <template>
            <v-data-table
              :headers="headers"
              :items="mitraList"
              class="elevation-0"
              mobile-breakpoint="0"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td
                    class="detailLink"
                    @click="getDetail(props.item.pending_task_id)"
                  >
                    {{ props.item.owner_name }}
                  </td>
                  <td>{{ props.item.email }}</td>
                  <td>{{ props.item.phone_number }}</td>
                  <td>{{ props.item.store_name }}</td>
                  <td>{{ getDate(props.item.created_at) }}</td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>

      <v-dialog v-model="dialog" max-width="1000">
        <v-card class="mx-auto" outlined>
          <v-app-bar dense color="transparent">
            <v-toolbar-title>
              <h5>Detail Pengguna</h5>
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0">
            <div class="pa-5">
              <v-row>
                <v-col class="col-3">
                  Nama Pemilik
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.ownerName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  No. KTP
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.ktpNumber }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Email
                </v-col>
                <v-col class="col-9"> :&ensp; {{ dataDetail.email }} </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nomor Telepon
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.phoneNumber }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nama Toko
                </v-col>
                <v-col class="col-9">
                  :&ensp; {{ dataDetail.storeName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Alamat
                </v-col>
                <v-col class="col-9"> :&ensp; {{ dataDetail.address }} </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nomor Rekening
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                    dense
                    v-model="dataDetail.accountNumber"
                    placeholder="Masukkan Nomor Rekening"
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Nama Pemilik Rekening
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                    dense
                    v-model="dataDetail.accountName"
                    placeholder="Masukkan Nama Pemilik Rekening"
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Kode Bank
                </v-col>
                <v-col class="col-6">
                  <v-autocomplete
                    dense
                    v-model="dataDetail.bankCode"
                    :items="bankCodeList"
                    item-value="bank_id"
                    label="Masukkan Kode Bank"
                    outlined
                  >
                    <template slot="selection" slot-scope="data">
                      ({{ data.item.bank_code }})
                      {{ data.item.bank_name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      ({{ data.item.bank_code }})
                      {{ data.item.bank_name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-3">
                  Kelurahan
                </v-col>
                <v-col class="col-5 align-self-center">
                  <v-autocomplete
                    v-model="villageSelect"
                    :items="dataDetail.villageList"
                    item-text="village_name"
                    item-value="postal_code_id"
                    placeholder="Masukkan Nama Kelurahan"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row v-if="flagReject">
                <v-col class="col-3">
                  Alasan
                </v-col>
                <v-col class="col-5 align-self-center">
                  <v-textarea
                    v-model="reasonMessage"
                    outlined
                    counter
                    no-resize
                    clearable
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-container>
                <div class="d-flex justify-end mt-3">
                  <v-btn
                    color="success"
                    :loading="loadingApprove"
                    :disabled="disabledApprove"
                    @click="approve"
                    class="mr-5"
                    >Terima</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    :loading="loadingReject"
                    :disabled="disabledReject"
                    @click="reject"
                    class="mr-5"
                    >Tolak</v-btn
                  >
                </div>
              </v-container>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import CommonService from "@/services/CommonService";
import RegistrationService from "@/services/RegistrationService";

export default {
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      mitraList: [],
      loadingApprove: false,
      disabledApprove: false,
      loadingReject: false,
      disabledReject: false,
      reasonMessage: "",
      flagReject: false,
      isReasonReject: true,
      valid: true,
      headers: [
        { text: "Nama Pemilik", sortable: false },
        { text: "Email", sortable: false },
        { text: "Nomor Telepon", sortable: false },
        { text: "Nama Toko", sortable: false },
        { text: "Tanggal Dibuat", sortable: false }
      ],
      villageSelect: "",
      dataDetail: {
        id: "",
        ownerName: "",
        ktpNumber: "",
        email: "",
        phoneNumber: "",
        storeName: "",
        accountNumber: "",
        accountName: "",
        bankCode: "",
        address: "",
        postalCode: "",
        villageList: []
      },
      bankCodeList: []
    };
  },

  mounted() {
    this.getUser();
    this.getBank();
  },

  methods: {
    getBank() {
      CommonService.getAllBank()
        .then(response => {
          this.bankCodeList = response.data.data;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getUser() {
      const payload = {
        menu_name: "Register User"
      };

      RegistrationService.getRegistrationMitra(payload)
        .then(response => {
          this.mitraList = response.data.data;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getDetail(id) {
      // this.flagReject = false;

      this.$root.$loaderModal.start("Loading...");

      const payload = {
        menu_name: "Register User",
        id: id
      };

      RegistrationService.getRegistrationMitraDetail(payload)
        .then(response => {
          this.dataDetail.id = response.data.data.pending_task_id;
          this.dataDetail.ownerName = response.data.data.owner_name;
          this.dataDetail.ktpNumber = response.data.data.id_card_number;
          this.dataDetail.email = response.data.data.email;
          this.dataDetail.phoneNumber = response.data.data.phone_number;
          this.dataDetail.storeName = response.data.data.store_name;
          this.dataDetail.accountNumber = response.data.data.account_number;
          this.dataDetail.accountName = response.data.data.account_name;
          this.dataDetail.bankCode = response.data.data.bank_id;
          this.dataDetail.address =
            response.data.data.address + ", " + response.data.data.postal_code;
          this.dataDetail.postalCode = response.data.data.postal_code;

          const payload = {
            postal_code: response.data.data.postal_code
          };

          RegistrationService.getRegistrationSupplierVillage(payload)
            .then(response => {
              this.dataDetail.villageList = response.data.villages;
            })
            .catch(() => {
              this.$tostini({
                message: "Terjadi Kesalahan!",
                type: "error"
              });
            });

          this.dialog = true;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },
    approve() {
      this.loadingApprove = true;
      this.disabledApprove = true;
      this.disabledReject = true;

      if (
        this.villageSelect === "" ||
        this.dataDetail.accountNumber === "" ||
        this.dataDetail.accountName === "" ||
        this.dataDetail.bankCode === ""
      ) {
        this.$tostini({
          message: "Data Wajib Diisi!",
          type: "error"
        });

        this.loadingApprove = false;
        this.disabledApprove = false;
        this.disabledReject = false;
        this.disabledReject = false;
        return;
      }

      const payload = {
        pending_task_id: this.dataDetail.id,
        postal_code_id: this.villageSelect
      };

      RegistrationService.approveRegistrationMitra(payload)
        .then(response => {
          this.$tostini({
            message: response.data.message,
            type: "success"
          });
          this.dialog = false;
          this.getUser();
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingApprove = false;
          this.disabledApprove = false;
          this.disabledReject = false;
        });
    },
    reject() {
      // this.flagReject = true;

      // if (this.reasonMessage === "") {
      //   this.$tostini({
      //     message: "Alasan Wajib Diisi!",
      //     type: "error"
      //   });

      //   return;
      // }

      this.loadingReject = true;
      this.disabledReject = true;
      this.disabledApprove = true;

      const payload = {
        pending_task_id: this.dataDetail.id,
        reason_message: "Rejected by Admin"
      };

      RegistrationService.rejectRegistrationMitra(payload)
        .then(response => {
          this.$tostini({
            message: response.data.message,
            type: "error"
          });
          this.dialog = false;
          this.getUser();
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingReject = false;
          this.disabledApprove = false;
          this.disabledReject = false;
        });
    }
  }
};
</script>
